// Login
export const LOGIN_SET_ERROR_LIST = "LOGIN_SET_ERROR_LIST";
export const LOGIN_SET_EMAIL_OR_PASSWORD = "LOGIN_SET_EMAIL_OR_PASSWORD";

// Rehydration Store
export const REHYDRATION_SET_REHYDRATION_VALUE = "REHYDRATION_SET_REHYDRATION_VALUE";

// Logout
export const RESET_INITIAL_STATE = "RESET_INITIAL_STATE";

// User Detail
export const USER_INFO_GET_DATA = "USER_INFO_GET_DATA";

// Forgot Password
export const FORGOT_PASSWORD_SET_ERROR_LIST = "FORGOT_PASSWORD_SET_ERROR_LIST";
export const FORGOT_PASSWORD_SET_EMAIL_OR_PASSWORD = "FORGOT_PASSWORD_SET_EMAIL_OR_PASSWORD";

// Seller Support
export const SELLER_SUPPORT_SET_ERROR_LIST = "SELLER_SUPPORT_SET_ERROR_LIST";
export const SELLER_SUPPORT_SET_EMAIL_OR_PASSWORD = "SELLER_SUPPORT_SET_EMAIL_OR_PASSWORD";

// Profile Common Action Types
export const PROFILE_EDIT_ERROR_LIST = "PROFILE_EDIT_ERROR_LIST";
export const PROFILE_CLEAR_LOCAL_STATE = "PROFILE_CLEAR_LOCAL_STATE";

// Profile -> Change Password
export const PROFILE_CLEAR_PASSWORD_DATA = "PROFILE_CLEAR_PASSWORD_DATA";
export const PROFILE_CHANGE_PASSWORD_DATA = "PROFILE_CHANGE_PASSWORD_DATA";

// Profile -> Contact Info
export const PROFILE_EDIT_CONTACT_DETAILS = "PROFILE_EDIT_CONTACT_DETAILS";
export const PROFILE_GET_CONTACT_INITIAL_DATA = "PROFILE_GET_CONTACT_INITIAL_DATA";

// Profile -> Pickup
export const PROFILE_GET_PICKUP_INITIAL_DATA = "PROFILE_GET_PICKUP_INITIAL_DATA";

// Profile -> Business Details
export const PROFILE_SET_IMAGE_SOURCE = "PROFILE_SET_IMAGE_SOURCE";
export const PROFILE_GET_BUSINESS_INITIAL_DATA = "PROFILE_GET_BUSINESS_INITIAL_DATA";

// Profile -> Holiday Info
export const PROFILE_RELOAD_HOLIDAY_DATA = "PROFILE_RELOAD_HOLIDAY_DATA";
export const PROFILE_EDIT_ENTERED_HOLIDAY_LIST = "PROFILE_EDIT_ENTERED_HOLIDAY_LIST";
export const PROFILE_CLEAR_ENTERED_HOLIDAY_LIST = "PROFILE_CLEAR_ENTERED_HOLIDAY_LIST";
export const PROFILE_GET_HOLIDAY_INFO_INITIAL_DATA = "PROFILE_GET_HOLIDAY_INFO_INITIAL_DATA";

// Profile -> Help (Seller Support)
export const PROFILE_SET_SELLER_SUPPORT_CODE = "PROFILE_SET_SELLER_SUPPORT_CODE";

// Dashboard
export const DASHBOARD_GET_INITIAL_DATA = "DASHBOARD_GET_INITIAL_DATA";

// Listing
export const LISTING_UPDATE_MRP = "LISTING_UPDATE_MRP";
export const LISTING_SET_SEARCH = "LISTING_SET_SEARCH";
export const LISTING_SET_NEW_MRP = "LISTING_SET_NEW_MRP";
export const LISTING_SET_PAGE_TYPE = "LISTING_SET_PAGE_TYPE";
export const LISTING_SET_MRP_IMAGE = "LISTING_SET_MRP_IMAGE";
export const LISTING_SET_NEW_PRICE = "LISTING_SET_NEW_PRICE";
export const LISTING_SET_NEW_DISCOUNT = "LISTING_SET_NEW_DISCOUNT";
export const LISTING_UPDATE_NEW_PRICE = "LISTING_UPDATE_NEW_PRICE";
export const LISTING_CLEAR_LOCAL_STATE = "LISTING_CLEAR_LOCAL_STATE";
export const LISTING_SET_SELECTED_ITEM = "LISTING_SET_SELECTED_ITEM";
export const LISTING_SET_MRP_MODAL_DATA = "LISTING_SET_MRP_MODAL_DATA";
export const LISTING_UPDATE_LISTING_DATA = "LISTING_UPDATE_LISTING_DATA";
export const LISTING_CLEAR_SELECTED_ITEM = "LISTING_CLEAR_SELECTED_ITEM";
export const LISTING_SET_ACTIVE_DISABLED = "LISTING_SET_ACTIVE_DISABLED";
export const LISTING_UPDATE_ACTIVE_DISABLED = "LISTING_UPDATE_ITEM_STATE";
export const LISTING_SET_PRICE_MODAL_DATA = "LISTING_SET_PRICE_MODAL_DATA";
export const LISTING_UPDATE_COMMISSION_RATE = "LISTING_UPDATE_COMMISSION_RATE";
export const LISTING_SET_NEW_COMMISSION_RATE = "LISTING_SET_NEW_COMMISSION_RATE";
export const LISTING_UPDATE_PRICE_HISTORY_DATA = "LISTING_UPDATE_PRICE_HISTORY_DATA";
export const LISTING_SET_COMMISSION_MODAL_DATA = "LISTING_SET_COMMISSION_MODAL_DATA";
export const LISTING_UPDATE_COMMISSION_HISTORY_DATA = "LISTING_UPDATE_COMMISSION_HISTORY_DATA";
export const LISTING_CLEAR_PRICE_HISTORY_MODAL_DATA = "LISTING_CLEAR_PRICE_HISTORY_MODAL_DATA";
export const LISTING_CLEAR_COMMISSION_HISTORY_MODAL_DATA = "LISTING_CLEAR_COMMISSION_HISTORY_MODAL_DATA";

// Reports
export const REPORTS_SET_DATE = "REPORTS_SET_DATE";
export const REPORTS_SET_INVOICE_NO = "REPORTS_SET_INVOICE_NO";

// Payments
export const PAYMENTS_RELOAD_DATA = "PAYMENTS_RELOAD_DATA";
export const PAYMENTS_CHANGE_DATA = "PAYMENTS_CHANGE_DATA";
export const PAYMENTS_GET_INITIAL_DATA = "PAYMENTS_GET_INITIAL_DATA";
export const PAYMENTS_CLEAR_LOCAL_STATE = "PAYMENTS_CLEAR_LOCAL_STATE";

// Issues
export const ISSUES_SET_FILTER = "ISSUES_SET_FILTER";
export const ISSUES_SET_SEARCH = "ISSUES_SET_SEARCH";
export const ISSUES_APPEND_DATA = "ISSUES_APPEND_DATA";
export const ISSUES_RELOAD_DATA = "ISSUES_RELOAD_DATA";
export const ISSUES_SET_PAGE = "ISSUES_SET_PAGE";
export const ISSUES_GET_INITIAL_DATA = "ISSUES_GET_INITIAL_DATA";
export const ISSUES_CLEAR_LOCAL_STATE = "ISSUES_CLEAR_LOCAL_STATE";

// Orders
export const ORDERS_TICK_ORDER = "ORDERS_TICK_ORDER";
export const ORDERS_SET_SEARCH = "ORDERS_SET_SEARCH";
export const ORDERS_SET_FILTER = "ORDERS_SET_FILTER";
export const ORDERS_APPEND_DATA = "ORDERS_APPEND_DATA";
export const ORDERS_ITEM_CHANGE = "ORDERS_ITEM_CHANGE";
export const ORDERS_RELOAD_DATA = "ORDERS_RELOAD_DATA";
export const ORDERS_SET_NEW_MRP = "ORDERS_SET_NEW_MRP";
export const ORDERS_SET_PAGE_TYPE = "ORDERS_SET_PAGE_TYPE";
export const ORDERS_AWB_CHECK_ALL = "ORDERS_AWB_CHECK_ALL";
export const ORDERS_MANIFEST_ORDER = "ORDERS_MANIFEST_ORDER";
export const ORDERS_AWB_ITEM_CHANGE = "ORDERS_AWB_ITEM_CHANGE";
export const ORDERS_CHECK_ALL_ORDERS = "ORDERS_CHECK_ALL_ORDERS";
export const ORDERS_SET_MANIFEST_TYPE = "ORDERS_SET_MANIFEST_TYPE";
export const ORDERS_PRESCRIPTION_ITEMS = "ORDERS_PRESCRIPTION_ITEMS";
export const ORDERS_SET_CANCEL_QUANTITY = "ORDERS_SET_CANCEL_QUANTITY";
export const ORDERS_SET_CONFIRM_EDIT_MRP = "ORDERS_SET_CONFIRM_EDIT_MRP";
export const ORDERS_ADD_PRESCRIPTION_ITEM = "ORDERS_ADD_PRESCRIPTION_ITEM";
export const ORDERS_SET_PRESCRIPTION_DATA = "ORDERS_SET_PRESCRIPTION_DATA";
export const ORDERS_SET_ORDER_ITEM_ID_CODE = "ORDERS_SET_ORDER_ITEM_ID_CODE";
export const ORDERS_SET_CONFIRM_UPDATE_MRP = "ORDERS_SET_CONFIRM_UPDATE_MRP";
export const ORDERS_DELETE_PRESCRIPTION_ITEM = "ORDERS_DELETE_PRESCRIPTION_ITEM";

// SignalR Connection
export const SIGNALR_COMMON_SET_NEW_COUNT = "SIGNALR_COMMON_SET_NEW_COUNT";
export const SIGNALR_TO_MANIFEST_ADD_NEW_ORDER = "SIGNALR_TO_MANIFEST_ADD_NEW_ORDER";

// Orders -> Common
export const ORDERS_GET_INITIAL_DATA = "ORDERS_GET_INITIAL_DATA";
export const ORDERS_CLEAR_LOCAL_STATE = "ORDERS_CLEAR_LOCAL_STATE";
export const ORDERS_COMMON_SET_SEARCH = "ORDERS_COMMON_SET_SEARCH";
export const ORDERS_COMMON_SET_PAGE_TYPE = "ORDERS_COMMON_SET_PAGE_TYPE";
export const ORDERS_COMMON_SET_PAGE_COUNT = "ORDERS_COMMON_SET_PAGE_COUNT";
export const ORDERS_COMMON_SET_SEARCH_PAGE_COUNT = "ORDERS_COMMON_SET_SEARCH_PAGE_COUNT";

// Orders -> To Deliver
export const ORDERS_TO_DELIVER_GET_DATA = "ORDERS_TO_DELIVER_GET_DATA";
export const ORDERS_TO_DELIVER_SET_SEARCH = "ORDERS_TO_DELIVER_SET_SEARCH";
export const ORDERS_TO_DELIVER_TICK_ORDER = "ORDERS_TO_DELIVER_TICK_ORDER";
export const ORDERS_TO_DELIVER_GET_ORDER_INFO = "ORDERS_TO_DELIVER_GET_ORDER_INFO";
export const ORDERS_TO_DELIVER_CLEAR_ORDER_INFO = "ORDERS_TO_DELIVER_CLEAR_ORDER_INFO";

// Orders -> To DIspatch
export const ORDERS_TO_DISPATCH_GET_DATA = "ORDERS_TO_DISPATCH_GET_DATA";
export const ORDERS_TO_DISPATCH_TICK_ORDER = "ORDERS_TO_DISPATCH_TICK_ORDER";
export const ORDERS_TO_DISPATCH_SET_SEARCH = "ORDERS_TO_DISPATCH_SET_SEARCH";
export const ORDERS_TO_DISPATCH_GET_ORDER_INFO = "ORDERS_TO_DISPATCH_GET_ORDER_INFO";
export const ORDERS_TO_DISPATCH_CLEAR_ORDER_INFO = "ORDERS_TO_DISPATCH_CLEAR_ORDER_INFO";

// Orders -> To Manifest
export const ORDERS_TO_MANIFEST_SET_MRP = "ORDERS_TO_MANIFEST_SET_MRP";
export const ORDERS_TO_MANIFEST_GET_DATA = "ORDERS_TO_MANIFEST_GET_DATA";
export const ORDERS_TO_MANIFEST_TICK_ITEM = "ORDERS_TO_MANIFEST_TICK_ITEM";
export const ORDERS_TO_MANIFEST_SET_SEARCH = "ORDERS_TO_MANIFEST_SET_SEARCH";
export const ORDERS_TO_MANIFEST_TICK_ORDER = "ORDERS_TO_MANIFEST_TICK_ORDER";
export const ORDERS_TO_MANIFEST_UNSELECT_ALL = "ORDERS_TO_MANIFEST_UNSELECT_ALL";
export const ORDERS_TO_MANIFEST_SET_CANCEL_TYPE = "ORDERS_TO_MANIFEST_SET_CANCEL_TYPE";
export const ORDERS_TO_MANIFEST_SET_SELECTED_ITEM = "ORDERS_TO_MANIFEST_SET_SELECTED_ITEM";
export const ORDERS_TO_MANIFEST_SET_SELECTED_ORDER = "ORDERS_TO_MANIFEST_SET_SELECTED_ORDER";
export const ORDERS_TO_MANIFEST_CLEAR_SELECTED_ITEM = "ORDERS_TO_MANIFEST_CLEAR_SELECTED_ITEM";
export const ORDERS_TO_MANIFEST_ADD_PRESCRIPTION_ITEM = "ORDERS_TO_MANIFEST_ADD_PRESCRIPTION_ITEM";
export const ORDERS_TO_MANIFEST_SET_PRESCRIPTION_DATA = "ORDERS_TO_MANIFEST_SET_PRESCRIPTION_DATA";
export const ORDERS_TO_MANIFEST_SET_PRESCRIPTION_ITEMS = "ORDERS_TO_MANIFEST_SET_PRESCRIPTION_ITEMS";
export const ORDERS_TO_MANIFEST_DELETE_PRESCRIPTION_ITEM = "ORDERS_TO_MANIFEST_DELETE_PRESCRIPTION_ITEM";

// Orders -> RTO
export const ORDERS_RETURNED_GET_DATA = "ORDERS_RETURNED_GET_DATA";
export const ORDERS_RETURNED_GET_ORDER_INFO = "ORDERS_RETURNED_GET_ORDER_INFO";
export const ORDERS_RETURNED_UPDATE_RECEIVED = "ORDERS_RETURNED_UPDATE_RECEIVED";
export const ORDERS_RETURNED_SET_RECEIVED_ITEM = "ORDERS_RETURNED_SET_RECEIVED_ITEM";

// Delivery Agent
export const AGENT_SET_NAME = "AGENT_SET_NAME";
export const AGENT_SEND_OTP = "AGENT_SEND_OTP";
export const AGENT_ADD_AGENT = "AGENT_ADD_AGENT";
export const AGENT_SET_MODAL_DATA = "AGENT_SET_MODAL_DATA";
export const AGENT_UPDATE_DETAILS = "AGENT_UPDATE_DETAILS";
export const AGENT_UPDATE_PASSWORD = "AGENT_UPDATE_PASSWORD";
export const AGENT_RESET_MODAL_DATA = "AGENT_RESET_MODAL_DATA";
export const AGENT_GET_INITIAL_DATA = "AGENT_GET_INITIAL_DATA";
export const AGENT_SELECT_THIS_AGENT = "AGENT_SELECT_THIS_AGENT";
export const AGENT_UPDATE_ERROR_LIST = "AGENT_UPDATE_ERROR_LIST";
export const AGENT_SET_CREATE_DETAILS = "AGENT_SET_CREATE_DETAILS";
export const AGENT_CLEAR_SELECTED_AGENT = "AGENT_CLEAR_SELECTED_AGENT";
