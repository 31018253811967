import {
	PROFILE_SET_SELLER_SUPPORT_CODE,
	PROFILE_GET_PICKUP_INITIAL_DATA,
	PROFILE_EDIT_ERROR_LIST,
	PROFILE_CLEAR_LOCAL_STATE,
	PROFILE_CHANGE_PASSWORD_DATA,
	PROFILE_CLEAR_PASSWORD_DATA,
	PROFILE_GET_BUSINESS_INITIAL_DATA,
	PROFILE_SET_IMAGE_SOURCE,
	PROFILE_GET_CONTACT_INITIAL_DATA,
	PROFILE_EDIT_CONTACT_DETAILS,
	PROFILE_GET_HOLIDAY_INFO_INITIAL_DATA,
	PROFILE_RELOAD_HOLIDAY_DATA,
	PROFILE_CLEAR_ENTERED_HOLIDAY_LIST,
	PROFILE_EDIT_ENTERED_HOLIDAY_LIST
} from "~/actions/action-types";

import { ProjectUrl } from "~/env";

//Common
export const ProfileGetInitialData = (type) => (dispatch) => {
	dispatch({ type: PROFILE_CLEAR_LOCAL_STATE });

	if (type != "change-password") {
		const url =
			type == "contact_info"
				? "/seller/get-contact-info"
				: type == "business_detail"
				? "/seller/get-business-info"
				: type == "pickup_info"
				? "/seller/get-pickup-info"
				: "/seller/get-holiday-list";

		const initial_action_type =
			type == "contact_info"
				? PROFILE_GET_CONTACT_INITIAL_DATA
				: type == "business_detail"
				? PROFILE_GET_BUSINESS_INITIAL_DATA
				: type == "pickup_info"
				? PROFILE_GET_PICKUP_INITIAL_DATA
				: PROFILE_GET_HOLIDAY_INFO_INITIAL_DATA;

		return fetch(ProjectUrl + url, {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
		})
			.then((res) => (res.ok ? res.json() : Promise.reject(res)))
			.then((data) => {
				dispatch({ type: initial_action_type, data: data });
			})
			.catch((error) => {
				if (error instanceof Error) alertify.error(error);
				else error.text().then((data) => alertify.error(data));
			});
	}
};

// contact Info Actions
const ProfileUpdateContactData = (contact_name, contact_mobile) => {
	nanobar.go(50);

	fetch(ProjectUrl + "/seller/update-contact-info", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({
			mobile: contact_mobile,
			name: contact_name
		})
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then(() => {
			nanobar.go(100);
			alertify.success("Primary contact details saved");
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const ProfileContactOnChange = (key, value) => (dispatch) => {
	dispatch({ type: PROFILE_EDIT_CONTACT_DETAILS, key, value });
};

export const ProfileContactOnFocus = (key) => (dispatch, getState) => {
	let error_list = getState().profile_common_store.error_list;

	if (key === "contact_name") {
		delete error_list["contact_name"];
	}

	if (key === "contact_mobile") {
		delete error_list["contact_mobile"];
	}

	dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list });
};

export const ProfileContactValidation = () => (dispatch, getState) => {
	const contact_name = getState().profile_contact_store.datalist.contact_name;
	const contact_mobile = getState().profile_contact_store.datalist.contact_mobile;
	let mobile_validator = /^([6-9]{1})([0-9]{9})$/;
	let error_list = getState().profile_common_store.error_list;

	if (contact_name.length === 0) {
		error_list["contact_name"] = "Contact Name cannot be empty";
	} else if (contact_name.length < 3) {
		error_list["contact_name"] = "Name cannot be less than 3 characters";
	}

	if (contact_mobile.length === 0) {
		error_list["contact_mobile"] = "Mobile No. cannot be empty";
	} else if (!mobile_validator.test(contact_mobile)) {
		error_list["contact_mobile"] = "Enter valid mobile no.";
	}

	if (Object.keys(error_list).length > 0) {
		dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list: error_list });
	}

	if (Object.keys(error_list).length === 0) {
		ProfileUpdateContactData(contact_name, contact_mobile);
	}
};

//Change Password Actions
const ProfileUpdateChangePasswordData = (current_password, new_password, confirm_password) => (dispatch) => {
	nanobar.go(50);

	fetch(ProjectUrl + "/seller/update-new-password", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({
			old_password: current_password,
			new_password: new_password,
			confirm_password: confirm_password
		})
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then(() => {
			nanobar.go(100);
			alertify.success("Password Changed");
			dispatch({ type: PROFILE_CLEAR_PASSWORD_DATA });
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const ProfileSetPasswordOnChange = (key, value) => (dispatch) => {
	dispatch({ type: PROFILE_CHANGE_PASSWORD_DATA, key, value });
};

export const ProfileChangePasswordOnFocus = (key) => (dispatch, getState) => {
	let error_list = getState().profile_common_store.error_list;

	if (key === "current_password") {
		delete error_list["current_password"];
	}
	if (key === "new_password") {
		delete error_list["new_password"];
	}
	if (key === "confirm_password") {
		delete error_list["confirm_password"];
	}

	dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list: error_list });
};

export const ProfileChangePasswordValidation = () => (dispatch, getState) => {
	const current_password = getState().profile_change_password_store.current_password;
	const new_password = getState().profile_change_password_store.new_password;
	const confirm_password = getState().profile_change_password_store.confirm_password;
	let error_list = getState().profile_common_store.error_list;
	let password_validator = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$&_~-])[a-zA-Z0-9#!@$&_~-]{10,}$/;

	if (current_password.length === 0) {
		error_list["current_password"] = "Current password cannot be empty";
	} else if (current_password.length < 8) {
		error_list["current_password"] = "Current password cannot be less than 8 characters";
	}

	if (new_password.length === 0) {
		error_list["new_password"] = "New password cannot be empty";
	} else if (new_password.length < 10) {
		error_list["new_password"] = "New password cannot be less than 10 characters";
	} else if (!password_validator.test(new_password)) {
		error_list["new_password"] = "Password should be at least 1 capital letter, 1 small letter, 1 number and 1 special symbol from #!@$&_~-";
	}

	if (confirm_password.length === 0) {
		error_list["confirm_password"] = "Confirm password cannot be empty";
	}

	if (new_password.length >= 10) {
		if (new_password !== confirm_password) {
			error_list["confirm_password"] = "New password & confirm password should be same";
		}
	}

	if (Object.keys(error_list).length > 0) {
		dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list: error_list });
	} else if (Object.keys(error_list).length === 0) {
		dispatch(ProfileUpdateChangePasswordData(current_password, new_password, confirm_password));
	}
};

//Pickup
export const ProfileUpdateWeeklyOff = (weekly_off) => (dispatch) => {
	nanobar.go(50);

	fetch(ProjectUrl + "/seller/update-weekly-off", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ weekly_off: weekly_off })
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then(() => {
			nanobar.go(100);
			alertify.success("Weekly Off updated");
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

//Holiday Info Actions
const ProfileGetHolidayList = () => (dispatch) => {
	nanobar.go(50);

	fetch(ProjectUrl + "/seller/get-holiday-list", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			nanobar.go(100);
			dispatch({ type: PROFILE_RELOAD_HOLIDAY_DATA, data });
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const ProfileSetHolidayOnChange = (key, value) => (dispatch) => {
	dispatch({ type: PROFILE_EDIT_ENTERED_HOLIDAY_LIST, key, value });
};

export const ProfileEditHolidayOnFocus = (key) => (dispatch, getState) => {
	let error_list = getState().profile_common_store.error_list;

	if (error_list[key] != "") {
		delete error_list[key];
	}

	dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list: error_list });
};

export const ProfileDeleteHoliday = (id) => (dispatch) => {
	nanobar.go(50);

	fetch(ProjectUrl + "/seller/delete-holiday", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ holiday_id: id })
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then(() => {
			nanobar.go(100);
			alertify.success("Holiday deleted");
			dispatch(ProfileGetHolidayList());
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const ProfileGetSaveHolidayList = () => (dispatch, getState) => {
	let entered_holiday_list = getState().profile_holiday_store.entered_holiday_list;
	let error_list = getState().profile_common_store.error_list;

	if (entered_holiday_list === "") {
		error_list["entered_holiday_list"] = "Holiday date cannot be empty";
	}

	if (Object.keys(error_list).length > 0) {
		dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list });
	} else if (Object.keys(error_list).length === 0) {
		nanobar.go(50);

		fetch(ProjectUrl + "/seller/save-holiday", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ holiday_date: entered_holiday_list })
		})
			.then((res) => (res.ok ? res.text() : Promise.reject(res)))
			.then(() => {
				nanobar.go(100);
				alertify.success("Holiday details saved");
				dispatch({ type: PROFILE_CLEAR_ENTERED_HOLIDAY_LIST, value: "" });
				dispatch(ProfileGetHolidayList());
			})
			.catch((error) => {
				nanobar.go(100);
				if (error instanceof Error) alertify.error(error);
				else error.text().then((data) => alertify.error(data));
			});
	}
};

//Help (Seller Support )
export const ProfileCreateSellerSupportCode = () => (dispatch) => {
	nanobar.go(50);

	fetch(ProjectUrl + "/seller/generate-seller-support-code", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then((data) => {
			nanobar.go(100);
			dispatch({ type: PROFILE_SET_SELLER_SUPPORT_CODE, value: data });
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
			dispatch({ type: PROFILE_SET_SELLER_SUPPORT_CODE, value: "" });
		});
};

//Business Details
export const ProfileOpenZoomImg = (src) => (dispatch) => {
	dispatch({ type: PROFILE_SET_IMAGE_SOURCE, value: src });
	return true;
};
