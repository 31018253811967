import { ProjectUrl } from "~/env";

import { USER_INFO_GET_DATA, RESET_INITIAL_STATE, LOGIN_SET_ERROR_LIST, LOGIN_SET_EMAIL_OR_PASSWORD } from "~/actions/action-types";

export const LoginSetErrorList = (field, message) => ({ type: LOGIN_SET_ERROR_LIST, field: field, mode: "add", message: message });
export const LoginSetEmailOrPassword = (field, value) => ({ type: LOGIN_SET_EMAIL_OR_PASSWORD, field: field, value: value });
export const LoginOnFocus = (field) => ({ type: LOGIN_SET_ERROR_LIST, field: field, mode: "delete" });

export const GetUserDetails = () => (dispatch, getState) => {
	const seller_id_code = getState().user_info_store.id_code;

	return fetch(ProjectUrl + "/login/get-user-details", {
		method: "POSt",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			if (data.logged_in_success) {
				if (data.id_code != seller_id_code) {
					dispatch({ type: RESET_INITIAL_STATE });
				}
				dispatch({ type: USER_INFO_GET_DATA, data: data });
			}
			return Promise.resolve(data.logged_in_success);
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

export const LoginGetLogout = () => (dispatch) => {
	return fetch(ProjectUrl + "/login/logout", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? dispatch({ type: RESET_INITIAL_STATE }) : Promise.reject(res)))
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

export const LoginCheckValidation = () => (dispatch, getState) => {
	let email = getState().email;
	let password = getState().password;
	let email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;

	if (email == "") {
		dispatch(LoginSetErrorList("email", "Enter User Id"));
	} else if (!email_validator.test(email)) {
		dispatch(LoginSetErrorList("email", "Enter a valid User Id"));
	}

	if (password == "") {
		dispatch(LoginSetErrorList("password", "Enter password"));
	}

	if (email != "" && password != "" && email_validator.test(email)) {
		return fetch(ProjectUrl + "/login/user-login", {
			method: "POSt",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ email: email, password: password })
		})
			.then((res) => (res.ok ? res.json() : Promise.reject(res)))
			.then((data) => {
				if (!data) {
					throw new Error("The password / email seems to be incorrect");
				}
			})
			.catch((error) => {
				if (error instanceof Error) alertify.error(error.message);
				else error.text().then((data) => alertify.error(data));
				return Promise.reject();
			});
	} else {
		return Promise.reject();
	}
};
