import MicroModal from "micromodal";

import Modal from "@bit/healthmug.admin.modal";
import Button from "@bit/healthmug.admin.button";

const ConfirmationModal = ({
	id,
	data,
	confirm_style_danger,
	confirm_style_disabled,
	confirm_click,
	cancel_click,
	confirm_label = "Yes",
	cancel_label = "No"
}) => {
	const confirm = () => {
		confirm_click ? confirm_click() : null;
	};

	const cancel = () => {
		cancel_click ? cancel_click() : null;
		MicroModal.close(id);
	};

	return (
		<Modal id={id} slide micro no_overlay_close no_esc_close>
			<div className="p-5">
				{data.title != "" ? <div className="text-md font-medium text-gray">{data.title}</div> : null}
				{data.content != "" ? <div className="mt-2">{data.content}</div> : null}
				<div className="text-right mt-4">
					<Button onClick={cancel} disabled={confirm_style_disabled} primary border className="mr-4">
						{cancel_label}
					</Button>
					<Button onClick={confirm} danger={confirm_style_danger} primary={!confirm_style_danger} disabled={confirm_style_disabled}>
						{confirm_label}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmationModal;
